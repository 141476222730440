import React from 'react'
import { Loader2 } from 'lucide-react'

const LoadingSpinner = () => {
  return (
    <div className="flex justify-center items-center h-full">
      <div className="animate-spin">
        <Loader2 size={48} className="text-blue-500" />
      </div>
    </div>
  )
}

export default LoadingSpinner

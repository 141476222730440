import React, { Suspense } from 'react'
import { Routes, Route, Link, Navigate } from 'react-router-dom'
import { Gamepad2, Home, Play, Users } from 'lucide-react'
import LoadingSpinner from './components/LoadingSpinner'

// Lazy-loaded pages and games
const HomePage = React.lazy(() => import('./pages/HomePage'))
const GameOne = React.lazy(() => import('./games/game-one'))
const GameTwo = React.lazy(() => import('./games/game-two'))
const GameThree = React.lazy(() => import('./games/game-three'))
const NotFoundPage = React.lazy(() => import('./pages/NotFoundPage'))

function App() {
  return (
    <div className="min-h-screen bg-gray-100 p-4">
      <nav className="mb-8">
        <ul className="flex space-x-4 justify-center items-center">
          <li>
            <Link
              to="/"
              className="flex items-center text-blue-600 hover:text-blue-800 transition-colors"
            >
              <Home className="mr-2" /> Home
            </Link>
          </li>
          <li>
            <Link
              to="/games/one"
              className="flex items-center text-green-600 hover:text-green-800 transition-colors"
            >
              <Gamepad2 className="mr-2" /> Game 1
            </Link>
          </li>
          <li>
            <Link
              to="/games/two"
              className="flex items-center text-purple-600 hover:text-purple-800 transition-colors"
            >
              <Play className="mr-2" /> Game 2
            </Link>
          </li>
          <li>
            <Link
              to="/games/three"
              className="flex items-center text-yellow-600 hover:text-yellow-800 transition-colors"
            >
              <Users className="mr-2" /> Snake Game
            </Link>
          </li>
        </ul>
      </nav>

      <Suspense fallback={<LoadingSpinner />}>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/games/one" element={<GameOne />} />
          <Route path="/games/two" element={<GameTwo />} />
          <Route path="/games/three" element={<GameThree />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </div>
  )
}

export default App
